import React, {FC} from "react";
import {NavLink, Outlet} from "react-router-dom";

interface ListingLayoutData {
}

const TabLayout: FC<ListingLayoutData> = (props) => {
    return (
        <div className="container">
            <div className="bs-page-title pt-3">Tools</div>
            <div className="row gx-lg-8 gx-xl-16">
                <div className="col-lg-3 col-xxl-2 border-end mb-4">
                    <div className="d-block d-lg-none mb-2">
                        <button className="btn border" type="button" data-bs-toggle="collapse" data-bs-target="#asideNav" aria-controls="asideNav" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <i className="bi bi-list me-1"></i>Tools Menu
                        </button>
                    </div>
                    <nav className="navbar-expand-lg position-sticky" style={{top: '4.5rem'}}>
                        <div className="collapse navbar-collapse flex-column" id="asideNav">
                            <ul id="section-chart" className="nav nav-pills nav-pills-flush flex-column w-100 gap-1">
                                <li className="nav-item">
                                    <NavLink to="/exportData" className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>CSV
                                        Export</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/balancecheck-tool"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Account Balance
                                        Checker</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/tokencheck-tool"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Token Supply
                                        Checker</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/tokentracker"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Token Standard
                                        Checker</NavLink>
                                </li>
                                <li className="nav-item">
                                    <hr className="my-3"/>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/find-similar-contracts"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Similar Contract Search
                                        (WIP)
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/vyper" className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Vyper
                                        Online Compiler (WIP)</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/opcode-tool" className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Bytecode
                                        to Opcode (WIP)</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/pushTx" className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Broadcast
                                        Transaction (WIP)</NavLink>
                                </li>
                                <li className="nav-item">
                                    <hr className="my-3"/>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/unitconverter" className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Unit
                                        Converter</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/tokenunitconverter"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Token Unit
                                        Converter</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/baseunitconversion"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Number Base
                                        Conversion</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/stringbytesconversion"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>String Byte32
                                        Conversion</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/epochtoutc" className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Unix
                                        Epoch - UTC Conversion</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/txdecoder" className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Tx
                                        Decoder</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/inputdatadecoder" className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Input Data Decoder
                                        </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/constructordecoder"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Constructor
                                        Decoder</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/eventdecoder" className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Event
                                        Decoder</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/calldatadecoder" className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Calldata
                                        Decoder</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/vanityaddressgenerator"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Vanity Address
                                        Generator</NavLink>
                                </li>
                                <li className="nav-item">
                                    <hr className="my-3"/>
                                </li>

                                <li className="nav-item">
                                    <NavLink to="/AI"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium d-flex justify-content-between align-items-center group py-2 px-3 active' : 'nav-link fw-medium d-flex justify-content-between align-items-center group py-2 px-3')}>Code
                                        Reader <i className="far fa-arrow-up-right text-muted group-hover ms-1"></i></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/searchcontract"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium d-flex justify-content-between align-items-center group py-2 px-3 active' : 'nav-link fw-medium d-flex justify-content-between align-items-center group py-2 px-3')}>Smart
                                        Contract Search (WIP)<i className="far fa-arrow-up-right text-muted group-hover ms-1"></i></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/contractdiffchecker"
                                             className={({isActive}) => (isActive ? 'nav-link fw-medium d-flex justify-content-between align-items-center group py-2 px-3 active' : 'nav-link fw-medium d-flex justify-content-between align-items-center group py-2 px-3')}>Contract
                                        Diff Checker (WIP)<i className="far fa-arrow-up-right text-muted group-hover ms-1"></i></NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className="col-lg-9 col-xxl-10">
                    <div className="pb-3 px-3">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabLayout;
