import { useState } from "react";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import ERC20 from "../common/tokenDeployer/contracts/erc20/ERC20.json";
import ERC721 from "../common/tokenDeployer/contracts/erc721/JsonURI/ERC721.json";

interface FunctionInput {
  name: string;
  type: string;
}

interface ContractFunction {
  name: string;
  inputs: FunctionInput[];
  outputs: { type: string }[];
  stateMutability: string;
}

const ContractInteractor = () => {
  const [contractType, setContractType] = useState<"token" | "nft">("token");
  const [customAbi, setCustomAbi] = useState<any[]>([]);
  const [contractAddress, setContractAddress] = useState("");
  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const [selectedFunction, setSelectedFunction] = useState<ContractFunction | null>(null);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const [result, setResult] = useState<string>("");
  

  const getAbi = () => {
    switch (contractType) {
      case "token":
        return ERC20.abi;
      case "nft":
        return ERC721.abi;
      default:
        return [];
    }
  };

 
  const functions = getAbi().filter(
    (item) => item.type === "function"
  ) as ContractFunction[];

  const readFunctions = functions.filter((f) =>
    ["view", "pure"].includes(f.stateMutability)
  );

  const writeFunctions = functions.filter(
    (f) => !["view", "pure"].includes(f.stateMutability)
  );

  const connectToContract = async () => {
    try {
      if (!ethers.isAddress(contractAddress)) {
        toast.error("Invalid contract address");
        return;
      }

      if (!window.ethereum) {
        toast.error("Please install MetaMask");
        return;
      }

      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const newContract = new ethers.Contract(contractAddress, getAbi(), signer);

      setContract(newContract);
      toast.success("Connected to contract successfully");
    } catch (error: any) {
      toast.error(error.message || "Error connecting to contract");
    }
  };

  const handleFunctionSelect = (func: ContractFunction) => {
    setSelectedFunction(func);
    setInputValues({});
    setResult("");
  };

  const handleInputChange = (name: string, value: string) => {
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContractTypeChange = (type: "token" | "nft") => {
    setContractType(type);
    setContract(null);
    setSelectedFunction(null);
    setResult("");
  };

  const executeFunction = async () => {
    if (!contract || !selectedFunction) return;

    try {
      const args = selectedFunction.inputs.map(
        (input) => inputValues[input.name]
      );

      const isWrite = !["view", "pure"].includes(
        selectedFunction.stateMutability
      );
      let response;

      if (isWrite) {
        const tx = await contract[selectedFunction.name](...args);
        await tx.wait();
        response = "Transaction successful";
      } else {
        response = await contract[selectedFunction.name](...args);
      }

      setResult(response.toString());
      toast.success(
        isWrite
          ? "Transaction completed successfully"
          : "Read operation successful"
      );
    } catch (error: any) {
      toast.error(error.reason || error.message || "Error executing function");
    }
  };

  return (
    <div className="ci-container">
      <section className="ci-connect-section">
        <h2 className="ci-section-title">Connect to Contract</h2>
        
        <div className="ci-contract-type-selector mb-4">
          <label className="ci-label block mb-2">Contract Type:</label>
          <select 
            value={contractType}
            onChange={(e) => handleContractTypeChange(e.target.value as "token" | "nft")}
            className="ci-select w-full p-2 border rounded"
          >
            <option value="token">ERC20 Token</option>
            <option value="nft">ERC721 NFT</option>

          </select>
        </div>


        <div className="ci-input-group">
          <input
            type="text"
            value={contractAddress}
            onChange={(e) => setContractAddress(e.target.value)}
            placeholder="Enter contract address"
            className="ci-input"
          />
          <button onClick={connectToContract} className="ci-button">
            Connect
          </button>
        </div>
      </section>

      {contract && (
        <section className="ci-interaction-section">
          <div className="ci-functions-container">
            <div className="ci-function-list">
              <h3 className="ci-subtitle">Read Functions</h3>
              {readFunctions.map((func) => (
                <button
                  key={func.name}
                  onClick={() => handleFunctionSelect(func)}
                  className={`ci-function-button ${
                    selectedFunction?.name === func.name ? "ci-selected" : ""
                  }`}
                >
                  {func.name}
                </button>
              ))}
            </div>

            <div className="ci-function-list">
              <h3 className="ci-subtitle">Write Functions</h3>
              {writeFunctions.map((func) => (
                <button
                  key={func.name}
                  onClick={() => handleFunctionSelect(func)}
                  className={`ci-function-button ${
                    selectedFunction?.name === func.name ? "ci-selected" : ""
                  }`}
                >
                  {func.name}
                </button>
              ))}
            </div>
          </div>

          {selectedFunction && (
            <div className="ci-execution-panel">
              <h3 className="ci-subtitle">Function: {selectedFunction.name}</h3>

              {selectedFunction.inputs.map((input) => (
                <div key={input.name} className="ci-input-group">
                  <label className="ci-label">
                    {input.name} ({input.type}):
                  </label>
                  <input
                    type="text"
                    value={inputValues[input.name] || ""}
                    onChange={(e) =>
                      handleInputChange(input.name, e.target.value)
                    }
                    placeholder={`Enter ${input.type}`}
                    className="ci-input"
                  />
                </div>
              ))}

              <button onClick={executeFunction} className="ci-execute-button">
                {["view", "pure"].includes(selectedFunction.stateMutability)
                  ? "Read"
                  : "Execute"}
              </button>

              {result && (
                <div className="ci-result">
                  <h4 className="ci-result-title">Result:</h4>
                  <pre className="ci-result-content">{result}</pre>
                </div>
              )}
            </div>
          )}
        </section>
      )}
    </div>
  );
};

export default ContractInteractor;