import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useSize from "../../../common/hooks/useSize";
import { useTranslation } from "react-i18next";
import Ads from "../ads";
import WalletButton from "../metamask/support-files/connect-wallet";
import axios from "axios";
import { common } from "../../../store/slicer/common";
interface HeaderMenuProps {}

interface MenuItemProps {
  name: string;
  route: string;
  subMenu?: MenuItemProps[];
}

const HeaderMenu: FC<HeaderMenuProps> = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const _tokenType = useSelector((e: Object) => (e as any)?.common?.tokenType);
  const _config = useSelector((e: Object) => (e as any)?.common?.config);
  const { width } = useSize();

  const handleClick = () => {
    if (width < 992) {
      // @ts-ignore
      document.getElementById("navbarTogglerDemo01").classList.remove("show");
    }
  };
  const [menuList, setMenuList] = useState<any>([
    { name: `${t("Home")}`, route: "/" },
    // {name: "Trending Token", route: "/trending-token", tag: "Live"},
    { name: `${t("Ask AI")}`, route: "/AI", tag: "Live" },
    {
      name: `${t("Blockchain")}`,
      route: "/txs",
      subMenu: [
        { name: `${t("Transactions")}`, route: "/txs" },
        {
          name: `${t("Pending Transactions")}`,
          route: "/txsPending",
          // tag: `${t("Live")}`,
        },
        {
          name: `${t("Contract Internal Transactions")}`,
          route: "/txsInternal",
          tag: `${t("WIP")}`,
        },
        { divider: true },
        // {name: 'Beacon Deposits', route: '/txsBeaconDeposit', tag: 'WIP'},
        // {name: 'Beacon Withdrawals', route: '/txsBeaconWithdrawal', tag: 'WIP'},
        {
          name: "Masternodes",
          route: "/masternodes",
          // tag: "Live",
          hide: process.env.REACT_APP_PREFIX !== "xdc",
        },
        { divider: true, hide: process.env.REACT_APP_PREFIX !== "xdc" },
        { name: `${t("View Blocks")}`, route: "/blocks" },
        { divider: true },
        {
          name: `${t("Top Accounts")}`,
          route: "/accounts",
          // tag: `${t("Live")}`,
        },
        {
          name: `${t("Verified Contracts")}`,
          route: "/contracts",
          // tag: `${t("Live")}`,
        },
      ],
    },
    {
      name: `${t("Tokens")}`,
      route: "/tokens",
      subMenu: [
        {
          name: `${t("Top Tokens")} (${_tokenType}-20)`,
          route: "/tokens",
          // tag: `${t("Live")}`,
        },
        {
          name: `${t("Token Transfers")} (${_tokenType}-20)`,
          route: "/tokentxns",
          // tag: `${t("Live")}`,
        },
        { divider: true },
        {
          name: `${t("Top Tokens")} (${_tokenType}-404)`,
          route: "/tokens/xrc404",
          // tag: `${t("Live")}`,
        },
        {
          name: `${t("Token Transfers")} (${_tokenType}-404)`,
          route: "/tokentxns/xrc404",
          // tag: `${t("Live")}`,
        },
        // {divider: true},
        // {name: `Top Tokens (${_tokenType}-1155)`, route: '/tokens/xrc1155', tag: 'Live'},
        // {name: `Token Transfers (${_tokenType}-1155)`, route: '/tokentxns/xrc1155', tag: 'Live'}
      ],
    },
    {
      name: "NFTs",
      route: "/nft-top-contracts",
      subMenu: [
        {
          name: `${t("Top NFTs")}`,
          route: "/nft-top-contracts",
          // tag: `${t("Live")}`,
        },
        {
          name: `${t("Top Mints")}`,
          route: "/nft-top-mints",
          // tag: `${t("Live")}`,
        },
        // {
        //     name: `${t("Latest Trades")}`,
        //     route: "/nft-trades",
        //     tag: `${t("WIP")}`,
        // },
        {
          name: `${t("Latest Transfers")}`,
          route: "/nft-transfers",
          // tag: `${t("Live")}`,
        },
        {
          name: `${t("Latest Mints")}`,
          route: "/nft-latest-mints",
          // tag: `${t("Live")}`,
        },
      ],
    },
    {
      name: `${t("Resources")}`,
      route: "/charts",
      subMenu: [
        {
          name: `${t("Charts And Stats")}`,
          route: "/charts",
          // tag: `${t("Live")}`,
        },
        {
          name: `${t("Top Statistics")}`,
          route: "/topstat",
          // tag: `${t("Live")}`,
        },
        // {
        //     name: `${t("Leaderboard")}`,
        //     route: "/leaderboard/advanced-filter",
        //     tag: `${t("WIP")}`,
        // },
        // {divider: true},
        // {name: `${t("Directory")}`, route: "/directory"},
        // {
        //     name: `${t("Newsletter")}`,
        //     route: "/newsletters",
        //     tag: `${t("WIP")}`,
        // },
        {
          name: `${t("Knowledge Base")}`,
          route: "https://www.xdc.dev/blocksscan",
          // tag: `${t("Live")}`,
          target: "_blank",
        },
      ],
    },
    {
      name: `${t("Developers")}`,
      route: "/apis",
      subMenu: [
        { name: `${t("API Plans")}`, route: "/apis" },
        {
          name: `${t("API Documentation")}`,
          route: "https://docs.blocksscan.io/",
          // tag: `${t("Live")}`,
          target: "_blank",
        },
        { divider: true },
        {
          name: `${t("Code Reader")}`,
          route: "/AI",
          // tag: `${t("Live")}`,
        },
        {
          name: `${t("Verify Contract")}`,
          route: "/contracts/verify",
          // tag: `${t("Live")}`,
        },
        // {
        //     name: `${t("Similar Contract Search")}`,
        //     route: "/find-similar-contracts",
        //     tag: `${t("WIP")}`,
        // },
        // {
        //     name: `${t("Smart Contract Search")}`,
        //     route: "/searchcontract",
        //     tag: `${t("WIP")}`,
        // },
        // {
        //     name: `${t("Contract Diff Checker")}`,
        //     route: "/contractdiffchecker",
        //     tag: `${t("WIP")}`,
        // },
        // {divider: true},
        // {
        //     name: `${t("Vyper Online Compiler")}`,
        //     route: "/vyper",
        //     tag: `${t("WIP")}`,
        // },
        // {
        //     name: `${t("Bytecode to Opcode")}`,
        //     route: "/opcode-tool",
        //     tag: `${t("WIP")}`,
        // },
        {
          name: `${t("Broadcast Transaction")}`,
          route: "/pushTx",
          tag: `${t("WIP")}`,
        },
        {
          name: `${t("Faucet")}`,
          route: "/faucet",
          tag: `${t("WIP")}`,
        },

        {
          name: `${t("Dapps")}`,
          route: "/dapps",
          tag: `${t("WIP")}`,
        },
      ],
    },
    {
      name: `${t("More")}`,
      route: "/settings",
      megaMenu: true,
      subMenu: [
        {
          name: "Tools",
          subMenu: [
            { name: `${t("Unit Converter")}`, route: "/unitconverter" },
            { name: `${t("CSV Export")}`, route: "/exportData" },
            {
              name: `${t("Account Balance Checker")}`,
              route: "/balancecheck-tool",
            },
            { name: `${t("Settings")}`, route: "/settings" },
            { name: "Open AI", route: "/AI" },
            {
              name: "Input Data Decoder",
              route: "/inputdatadecoder",
              tag: `${t("WIP")}`,
            },
          ],
        },
        {
          name: "Explore",
          subMenu: [
            { name: `${t("Gas Tracker")}`, route: "/gastracker" },
            { name: `${t("Dex Tracker")}`, route: "/dex" },
            { name: `${t("Node Tracker")}`, route: "/nodetracker" },
            { name: `${t("Label Cloud")}`, route: "/labelcloud" },
            // {name: `${t("Domain Name Lookup")}`, route: "/name-lookuo"},
          ],
        },
        {
          name: "Services",
          subMenu: [
            // {name: `${t("Token Approvals")}`, route: "/tokenapprovalchecker"},
            // {name: `${t("Verified Signature")}`, route: "/verifiedSignatures"},
            // {name: `${t("Input Data Messages (IDM)")}`, route: "/idm"},
            { name: `${t("Advanced Filter")}`, route: "/advanced-filter" },
            {
              name: `${t("Network Analytics")}`,
              route: `${_config?.analytics}`,
              hide: !_config?.analytics,
            },
            {
              name: `${t("Contract Interaction")}`,
              route: "/interact",
              },
              {
                  name: `${t("Deploy ERC20")}`,
                  route : "/deploy-erc20"
              },
              {
                  name: `${t("Deploy ERC721")}`,
            route : "/deploy-erc721"  },
              
          ],
        },

      ],
    },
  ]);

  const isMenuItemActive = (menuItem: MenuItemProps): boolean => {
    if (location.pathname === menuItem.route) return true;
    if (menuItem.subMenu) {
      return menuItem.subMenu.some(
        (subMenuItem) => subMenuItem.route === location.pathname
      );
    }
    return false;
  };

  useEffect(() => {
    axios
      .get(
        `https://api.geckoterminal.com/api/v2/networks/${process.env.REACT_APP_BASE_UNIT?.toLowerCase()}/dexes?page=1`
      )
      .then((res: any) => {
        dispatch(common.actions.actionDefi(res?.data?.data));
        const subMenu: any[] = [];
        (res?.data?.data ?? []).forEach((e: any) => {
          subMenu.push({
            name: e?.attributes?.name,
            route: `/defi/${e?.id}`,
          });
        });
        setMenuList([
          ...menuList.slice(0, 5),
          {
            name: `${t("DeFi")}`,
            route: "#",
            subMenu: subMenu,
          },
          ...menuList.slice(5),
        ]);
      });
  }, []);

  return (
    <React.Fragment>
      <ul className="navbar-nav mb-2 mb-lg-0">
        {menuList?.map((e: any, i: number) => {
          const isActive = isMenuItemActive(e);
          return (
            <li
              className={`nav-item ${!!e.subMenu?.length ? "dropdown" : ""} ${
                e.megaMenu ? "position-initial" : ""
              }`}
              key={i}
              onClick={() => !e.subMenu?.length && handleClick()}
            >
              <React.Fragment>
                <Link
                  className={`nav-link ${
                    !!e.subMenu?.length ? "dropdown-toggle" : ""
                  } ${isActive ? "nav-active" : ""}`}
                  to={(!e.subMenu?.length ? true : width > 991) && e.route}
                  role="button"
                >
                  {e.name}&nbsp;
                  {!!e.subMenu?.length && (
                    <i className="bi bi-chevron-down b-fs-12"></i>
                  )}
                  {!e.subMenu?.length && e.tag === "Live" && (
                    <React.Fragment>
                      <span className="bs-dot h_green"></span>&nbsp;
                    </React.Fragment>
                  )}
                </Link>
                {!!e.subMenu?.length && (
                  <React.Fragment>
                    {e?.megaMenu ? (
                      <div
                        className="dropdown-menu dropdown-menu-border dropdown-menu-mega"
                        data-bs-popper="static"
                      >
                        <div className="row">
                          <div className="col-lg order-last order-lg-first">
                            <div className="d-flex flex-column bg-light h-100 rounded-3 p-2">
                              <div style={{ fontSize: "13px" }}>
                                <h6>Tools &amp; Services</h6>
                                <p>
                                  Discover more of Blocksscan's tools and
                                  services in one place.
                                </p>
                              </div>
                              <div
                                className="mt-auto"
                                style={{ fontSize: "13px" }}
                              >
                                <p className="text-muted mb-2">Sponsored</p>
                                <Ads />
                              </div>
                            </div>
                          </div>
                          {e?.subMenu?.map((s: any, i: number) => {
                            return (
                              <div key={i} className="col-sm p-2">
                                <p
                                  className="fw-semibold"
                                  style={{ padding: "0 12px 12px" }}
                                >
                                  {s?.name}
                                </p>
                                <ul className="list-unstyled">
                                  <SubMenu
                                    list={s?.subMenu}
                                    handleClick={handleClick}
                                  />
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <ul className={`dropdown-menu`}>
                        <SubMenu list={e?.subMenu} handleClick={handleClick} />
                      </ul>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            </li>
          );
        })}
        <li className="nav-item dropdown ">
          <WalletButton className="nav-item bs-sign-btn" />
        </li>
      </ul>
    </React.Fragment>
  );
};

const SubMenu = (props: any) => {
  return props?.list?.map((s: any, j: number) => {
    if (s?.hide) return null;
    if (s.divider) {
      return (
        <li key={j}>
          <hr className="dropdown-divider" />
        </li>
      );
    } else {
      return (
        <li key={j} onClick={props?.handleClick}>
          <Link className={`dropdown-item`} to={s.route} target={s.target}>
            {s.name}&nbsp;
            {s.tag === "Live" && (
              <React.Fragment>
                <span className="bs-dot h_green"></span>
                &nbsp;
                <span className="bs-badge h-small h-green">Live</span>
              </React.Fragment>
            )}
            {s.tag === "WIP" && (
              <span className="bs-badge h-small h-gray">WIP</span>
            )}
          </Link>
        </li>
      );
    }
  });
};
export default HeaderMenu;
function t(key: string): string {
  const translations: { [key: string]: string } = {
    Home: "Home",
    "Ask AI": "Ask AI",
    Blockchain: "Blockchain",
    Transactions: "Transactions",
    "Pending Transactions": "Pending Transactions",
    "Contract Internal Transactions": "Contract Internal Transactions",
    "View Blocks": "View Blocks",
    "Top Accounts": "Top Accounts",
    "Verified Contracts": "Verified Contracts",
    Tokens: "Tokens",
    "Top Tokens": "Top Tokens",
    "Token Transfers": "Token Transfers",
    NFTs: "NFTs",
    "Top NFTs": "Top NFTs",
    "Top Mints": "Top Mints",
    "Latest Transfers": "Latest Transfers",
    "Latest Mints": "Latest Mints",
    Resources: "Resources",
    "Charts And Stats": "Charts And Stats",
    "Top Statistics": "Top Statistics",
    "Knowledge Base": "Knowledge Base",
    Developers: "Developers",
    "API Plans": "API Plans",
    "API Documentation": "API Documentation",
    "Code Reader": "Code Reader",
    "Verify Contract": "Verify Contract",
    "Broadcast Transaction": "Broadcast Transaction",
    Faucet: "Faucet",
    Dapps: "Dapps",
    More: "More",
    "Unit Converter": "Unit Converter",
    "CSV Export": "CSV Export",
    "Account Balance Checker": "Account Balance Checker",
    Settings: "Settings",
    "Open AI": "Open AI",
    "Gas Tracker": "Gas Tracker",
    "Dex Tracker": "Dex Tracker",
    "Node Tracker": "Node Tracker",
    "Label Cloud": "Label Cloud",
    "Advanced Filter": "Advanced Filter",
    "Network Analytics": "Network Analytics",
  };
  return translations[key] || key;
}
