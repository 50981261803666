import { FC } from "react";
import { FormPropsERC721 } from "../common/tokenDeployer/types";

const Form: FC<FormPropsERC721> = ({
  isError,
  formState,
  setFormState,
  walletAddress,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  return (
    <div className="td-form">
      <div className="td-form-group">
        <label htmlFor="name" className="td-label">
          Collection Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formState.name}
          onChange={handleChange}
          className={`td-input ${
            isError.type === "name" ? "td-input-error" : ""
          }`}
          placeholder="MyCollection"
        />
        {isError.type === "name" && (
          <span className="td-error-message">{isError.message}</span>
        )}
        <span className="td-help-text">Enter the name of your Collection.</span>
      </div>

      <div className="td-form-group">
        <label htmlFor="symbol" className="td-label">
          Collection Symbol
        </label>
        <input
          type="text"
          id="symbol"
          name="symbol"
          value={formState.symbol}
          onChange={handleChange}
          className={`td-input ${
            isError.type === "symbol" ? "td-input-error" : ""
          }`}
          placeholder="MCL"
        />
        {isError.type === "symbol" && (
          <span className="td-error-message">{isError.message}</span>
        )}
        <span className="td-help-text">Enter your collection ticker.</span>
      </div>

      <div className="td-form-group">
        <label htmlFor="extension" className="td-label">
          Metadata Extension
        </label>
        <select
          id="extension"
          name="extension"
          value={formState.extension}
          onChange={handleChange}
          className={`td-input ${
            isError.type === "extension" ? "td-input-error" : ""
          }`}
        >
          <option value=".json">.json</option>
          <option value="none">none</option>
        </select>

        {isError.type === "symbol" && (
          <span className="td-error-message">{isError.message}</span>
        )}
        <span className="td-help-text">
          Enter the file extension type for your metadata.
        </span>
      </div>
      <div className="td-form-group">
        <label htmlFor="extension" className="td-label">
          Metadata URI
        </label>
        <input
          type="text"
          id="uri"
          name="uri"
          value={formState.uri}
          onChange={handleChange}
          className={`td-input ${
            isError.type === "symbol" ? "td-input-error" : ""
          }`}
          placeholder="ipfs://mycid/"
        />
        {isError.type === "uri" && (
          <span className="td-error-message">{isError.message}</span>
        )}
        <span className="td-help-text">
          Enter the CID.
        </span>
      </div>

      <div className="td-form-group">
        <label htmlFor="collectionSize" className="td-label">
          CollectionSize
        </label>
        <input
          type="text"
          id="collectionSize"
          name="collectionSize"
          value={formState.collectionSize}
          onChange={handleChange}
          className={`td-input ${
            isError.type === "supply" ? "td-input-error" : ""
          }`}
          placeholder="1000000000"
        />
        {isError.type === "collectionSize" && (
          <span className="td-error-message">{isError.message}</span>
        )}
        <span className="td-help-text">
          Set the initial supply of your token.
        </span>
      </div>
    </div>
  );
};

export default Form;
