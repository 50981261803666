import { useEffect, useState } from "react";
import axios from "axios";
import { Chain } from 'wagmi/chains';

const defaultCustomChain: Chain = {
    id: Number(process.env.REACT_APP_CUSTOM_CHAIN_ID) || 51, 
    name: process.env.REACT_APP_CUSTOM_CHAIN_NAME || "XDC Network",
    rpcUrls: {
        default: {
            http: [process.env.REACT_APP_CUSTOM_CHAIN_RPC_URL || "https://rpc.xinfin.network"], 
        },
        public: {
            http: [process.env.REACT_APP_CUSTOM_CHAIN_RPC_URL || "https://rpc.xinfin.network"], 
        },
    },
    network: process.env.REACT_APP_CUSTOM_CHAIN_NETWORK || "XDC Network", 
    nativeCurrency: {
        name: process.env.REACT_APP_CUSTOM_CHAIN_NATIVE_CURRENCY_NAME || "XDC",
        symbol: process.env.REACT_APP_BASE_UNIT|| "XDC",
        decimals: Number(process.env.REACT_APP_CUSTOM_CHAIN_NATIVE_CURRENCY_DECIMALS) || 18,
    },
};

const useCustomChain = () => {
    const [customChain, setCustomChain] = useState<Chain>(defaultCustomChain);

    useEffect(() => {
        const fetchRPCDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SOCKET_URL}/stats`);
                const rpcDetail = response.data.RPCDetail;

                if (rpcDetail) {
                    setCustomChain({
                        id: Number(rpcDetail.chainId) || customChain.id, 
                        name: rpcDetail.network || customChain.name,
                        rpcUrls: {
                            default: {
                                http: [rpcDetail.apiURL || customChain.rpcUrls.default.http[0]], 
                            },
                            public: {
                                http: [rpcDetail.apiURL || customChain.rpcUrls.public.http[0]], 
                            },
                        },
                        network: rpcDetail.network || customChain.network, 
                        nativeCurrency: customChain.nativeCurrency, // Keeps default as no native currency data in response
                    });
                }
            } catch (error) {
                console.error("Failed to fetch RPC details:", error);
            }
        };

        fetchRPCDetails();
    }, []);

    return customChain;
};

export default useCustomChain;
