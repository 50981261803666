'use client';

import { useState } from 'react';
import { ethers } from 'ethers';
import Form from './Form';
import { FormStatePropsERC721, ErrorState } from '../common/tokenDeployer/types';
import {  deployNFT } from '../common/tokenDeployer/utils';
import { toast } from 'react-toastify';
import WalletInfo from '../common/metamask/support-files/contractConnectedWallet';
import BCopy from "../../components/b-copy";

import {ERC721} from '../common/tokenDeployer/contracts/erc721/JsonURI/ERC721';
import { verifyContract } from '../common/tokenDeployer/utils/verifyContract';
export default function ERC721Deployer() {
  const [formState, setFormState] = useState<FormStatePropsERC721>({
    name: '',
    symbol: '',
    extension: '',
    uri: '',
    collectionSize: ''
  });

  const [isError, setIsError] = useState<ErrorState>({
    error: false,
    message: '',
    type: '',
  });

  const [walletAddress, setWalletAddress] = useState<string>('');

  
  const handleSubmit = async () => {
    const { name, symbol, extension, uri, collectionSize } = formState;


    if (!name) {
      setIsError({ error: true, message: 'Collection name is required.', type: 'name' });
      return;
    }
    if (!symbol) {
      setIsError({ error: true, message: 'Collection symbol is required.', type: 'symbol' });
      return;
    }
    if (!extension) {
      setIsError({ error: true, message: 'Metadata extension is required.', type: 'extension' });
      return;
    }
    if (!uri) {
      setIsError({ error: true, message: 'Metadata URI is required.', type: 'uri' });
      return;
    }
    if (!collectionSize) {
      setIsError({ error: true, message: 'Collection size is required.', type: 'collectionSize' });
      return;
    }

    setIsError({ error: false, message: '', type: '' });

    try {
      if (!window.ethereum) {
        throw new Error('MetaMask not installed');
      }

      const provider = new ethers.BrowserProvider(window.ethereum);
      const loadingToast = toast.loading(`Deploying ${name} NFT Collection`);
      
      const contract = await deployNFT({ 
        name, 
        symbol, 
        extension,
        uri,
        collectionSize,
        web3Provider: provider 
      });
      
      const deployTx = contract.deploymentTransaction();

      
      if (!deployTx) {
        throw new Error('Deployment transaction not found');
      }
      await deployTx.wait();

    
      await verifyContract(contract.target.toString(),ERC721);
      toast.dismiss(loadingToast);
      toast.success(
        <div>
          <p>{name} NFT Collection deployed successfully!</p>
          <a 
            href={`/tx/${deployTx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#3b82f6', textDecoration: 'underline' }}
          >
            View in Explorer
          </a>
          <p>Contract Address: {contract.target.toString()} <BCopy value={contract.target.toString()} /></p>
        </div>
      );
    } catch (error: any) {
      toast.error(error.reason || error.message || 'An unknown error occurred');
    }
  };

  return (
    <div className="td-container">
      <header className="td-header">
        <h1 className="td-title">NFT Collection Deployer</h1>
        <p className="td-subtitle">
          Easily deploy your ERC721 NFT Collection on the XDC Network.
        </p>
      </header>

      <section className="td-main-section">
<WalletInfo />
            <Form 
              isError={isError}
              formState={formState}
              setFormState={setFormState}
              walletAddress={walletAddress}
            />
            <button 
              onClick={handleSubmit}
              className="td-deploy-button"
            >
              Deploy Collection
            </button>
    
      </section>

      <section className="td-info-section">
        <h2 className="td-section-title">The ERC721 Contract</h2>
        <p className="td-section-text">
          This NFT deployer creates a standard ERC721 collection using OpenZeppelin's implementation.
          The deployed contract includes essential NFT functionality such as minting, transferring,
          and managing token URIs. The collection size will be fixed at deployment, and metadata can
          be stored on IPFS or any other decentralized storage solution.
        </p>
      </section>
    </div>
  );
}

