import { FC } from 'react';
import { FormProps } from '../common/tokenDeployer/types';

const Form: FC<FormProps> = ({ isError, formState, setFormState, walletAddress }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  return (
    <div className="td-form">
      <div className="td-form-group">
        <label htmlFor="name" className="td-label">Token Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formState.name}
          onChange={handleChange}
          className={`td-input ${isError.type === 'name' ? 'td-input-error' : ''}`}
          placeholder="MyToken"
        />
        {isError.type === 'name' && <span className="td-error-message">{isError.message}</span>}
        <span className="td-help-text">Enter the name of your token.</span>
      </div>

      <div className="td-form-group">
        <label htmlFor="symbol" className="td-label">Token Symbol</label>
        <input
          type="text"
          id="symbol"
          name="symbol"
          value={formState.symbol}
          onChange={handleChange}
          className={`td-input ${isError.type === 'symbol' ? 'td-input-error' : ''}`}
          placeholder="MTK"
        />
        {isError.type === 'symbol' && <span className="td-error-message">{isError.message}</span>}
        <span className="td-help-text">Enter your token ticker.</span>
      </div>

      <div className="td-form-group">
        <label htmlFor="supply" className="td-label">Token Supply</label>
        <input
          type="text"
          id="supply"
          name="supply"
          value={formState.supply}
          onChange={handleChange}
          className={`td-input ${isError.type === 'supply' ? 'td-input-error' : ''}`}
          placeholder="1000000000"
        />
        {isError.type === 'supply' && <span className="td-error-message">{isError.message}</span>}
        <span className="td-help-text">Set the initial supply of your token.</span>
      </div>
    </div>
  );
};

export default Form;