import React, { useMemo, useState } from "react";
import { apothemTokenInfo, devnetTokenInfo, networks } from "./tokenData";
import { Link } from "react-router-dom";
import { faucetService } from "../../store/actions";

const Faucet = () => {
  const [formData, setFormData] = useState({
    network: `${networks[0].value}`,
    address: "",
    token: "",
    amount: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [requestUuid, setRequestUuid] = useState("");
  const [txHash, setTxHash] = useState("");

  const lockoutTime = useMemo(() => {
    const time = new Date();
    time.setHours(time.getHours() + 24);
    return time.toLocaleString();
  }, []);

  const availableTokens = useMemo(() => {
    return formData.network === "apothem" ? apothemTokenInfo : devnetTokenInfo;
  }, [formData.network]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError("");
    setSuccess("");
  };

  const validateWalletAddress = (address: string): boolean => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  const checkRequestStatus = async (uuid: string) => {
    try {
      const response = await faucetService.checkStatus(uuid);
      const { status, txHash } = response.data;

      if (status === "COMPLETED") {
        setSuccess(`Transaction successful! Tx Hash: ${txHash}`);
        setTxHash(txHash);
        setLoading(false);
      } else if (status === "FAILED") {
        setError("Transaction failed. Please try again.");
        setLoading(false);
      } else {
        setTimeout(() => checkRequestStatus(uuid), 5000);
      }
    } catch {
      setError("Failed to check request status. Please try again.");
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);
    setRequestUuid("");

    if (!validateWalletAddress(formData.address)) {
      setError("Invalid wallet address format.");
      setLoading(false);
      return;
    }

    const selectedToken = availableTokens.find(
      (token) => token.symbol === formData.token
    );

    if (!selectedToken) {
      setError("Please select a token.");
      setLoading(false);
      return;
    }

    const amount = Number(formData.amount);
    if (isNaN(amount) || amount <= 0) {
      setError("Invalid amount.");
      setLoading(false);
      return;
    }

    if (amount > (selectedToken.maxLimit || 0)) {
      setError(
        `Amount exceeds max limit of ${selectedToken.maxLimit} ${selectedToken.symbol}.`
      );
      setLoading(false);
      return;
    }

    try {
      const ip = await fetch("https://api.ipify.org").then((res) => res.text());

      const payload = {
        address: formData.address,
        amount,
        tokenInfo: selectedToken,
        ip,
      };

      const response = await faucetService.requestTokens(payload);
      setRequestUuid(response.data.uuid);

      checkRequestStatus(response.data.uuid);
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.error || "Failed to process your request. Please try again.";
      setError(errorMessage);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="bs-page-title">Faucet</div>
        </div>
        <div className="pb-3">
          <div className="text-muted">
            <p>
              Request testnet tokens or NFTs to build and test your DApps. The
              daily withdrawal limit is 5 requests. You will be locked out for
              24 hours after your request.
            </p>
          </div>
        </div>
      </div>
      <div className="bs-card p-3">
        <div className="alert alert-warning mb-4">
          ⚠️ Withdrawing tokens will get you locked out until{" "}
          {lockoutTime}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Select Network</label>
                <select
                  name="network"
                  value={formData.network}
                  onChange={handleInputChange}
                  className="form-select"
                >
                  {networks.map((network) => (
                    <option key={network.value} value={network.value}>
                      {network.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Wallet Address</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  placeholder="0x..."
                  className="form-control"
                  required
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Select Token</label>
                <select
                  name="token"
                  value={formData.token}
                  onChange={handleInputChange}
                  className="form-select"
                  required
                >
                  <option value="">Select a Token</option>
                  {availableTokens.map((token) => (
                    <option key={token.symbol} value={token.symbol}>
                      {token.name} ({token.symbol}) - Max: {token.maxLimit}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Amount</label>
                <input
                  type="number"
                  name="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                  placeholder="Enter amount"
                  className="form-control"
                  min="0"
                  step="0.1"
                  required
                />
                {formData.token && (
                  <small className="form-text text-muted">
                    Max limit:{" "}
                    {availableTokens.find((t) => t.symbol === formData.token)
                      ?.maxLimit || "N/A"}
                  </small>
                )}
              </div>
            </div>
          </div>

          <button
            type="submit"
            className={`btn btn-primary w-100 ${loading ? "disabled" : ""}`}
          
          >
         Processing
          </button>
        </form>


        {error && <div className="alert alert-danger mt-3">{error}</div>}
        {success && (
          <div className="alert alert-success mt-3">
            <Link to={`https://apothem.xdcscan.io/tx/${txHash}`}>
              {success}
            </Link>
          </div>
        )}
        {requestUuid && loading && (
          <div className="alert alert-info mt-3">
            Request in progress. Tracking request: {requestUuid}
          </div>
        )}
        <p className="text-muted mt-3">
          {formData.token && formData.amount
            ? `You are requesting ${formData.amount} ${formData.token} on ${formData.network} testnet`
            : "Select a token and amount to request"}
        </p>
      </div>
    </React.Fragment>
  );
};

export default Faucet;

// import React, { useState } from 'react';

// const Faucet = () => {
//   const [formData, setFormData] = useState({
//     network: 'apothem',
//     address: '',
//     type: 'tokens',
//     amount: '5',
//     nftId: '1',
//   });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');

//   // Calculate lockout time (24 hours from now)
//   const lockoutTime = new Date();
//   lockoutTime.setHours(lockoutTime.getHours() + 24);
//   const lockoutTimeString = lockoutTime.toLocaleString();

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//     const { name, value } = e.target;
//     setFormData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//     setError('');
//     setSuccess('');
//   };

//   const validateWalletAddress = (address: string): boolean => {
//     return /^0x[a-fA-F0-9]{40}$/.test(address);
//   };

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     setError('');
//     setSuccess('');
//     setLoading(true);

//     if (!validateWalletAddress(formData.address)) {
//       setError('Invalid wallet address format');
//       setLoading(false);
//       return;
//     }

//     try {
//       // Simulate API call
//       await new Promise(resolve => setTimeout(resolve, 1500));
//       setSuccess(`Successfully sent ${formData.type === 'tokens' ?
//         `${formData.amount} XDC tokens` :
//         `NFT #${formData.nftId}`} to your address on ${formData.network} testnet!`);

//       // Reset form partially
//       setFormData(prev => ({
//         ...prev,
//         address: '',
//       }));
//     } catch (err) {
//       setError('Failed to process your request. Please try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <React.Fragment>
//       <div className="row">
//         <div className="col-lg-12">
//           <div className="bs-page-title"> Faucet</div>
//         </div>
//         <div className="pb-3">
//           <div className="text-muted">
//             <p>
//             Request testnet tokens or NFTs to build and test your DApps.
//             The daily withdrawal limit varies by network. You will be locked out for 24 hours after your request.
//             </p>
//           </div>
//         </div>
//       </div>
//       <div className="bs-card p-3">
//         <div className="alert alert-warning mb-4">
//           ⚠️ Withdrawing {formData.type === 'tokens' ? `${formData.amount} XDC` : `NFT #${formData.nftId}`} will
//           get you locked out until {lockoutTimeString}
//         </div>

//         <form onSubmit={handleSubmit}>
//           <div className="row mb-4">
//             <div className="col-md-6">
//               <div className="mb-3">
//                 <label className="form-label">Select Network</label>
//                 <select
//                   name="network"
//                   value={formData.network}
//                   onChange={handleInputChange}
//                   className="form-select"
//                 >
//                   <option value="apothem">Apothem Testnet</option>
//                   <option value="devnet">Devnet</option>
//                 </select>
//               </div>

//               <div className="mb-3">
//                 <label className="form-label">Wallet Address</label>
//                 <input
//                   type="text"
//                   name="address"
//                   value={formData.address}
//                   onChange={handleInputChange}
//                   placeholder="0x..."
//                   className="form-control"
//                   required
//                 />
//               </div>
//             </div>

//             <div className="col-md-6">
//               <div className="mb-3">
//                 <label className="form-label">Request Type</label>
//                 <select
//                   name="type"
//                   value={formData.type}
//                   onChange={handleInputChange}
//                   className="form-select"
//                 >
//                   <option value="tokens">XDC Tokens</option>
//                   <option value="nft">NFT</option>
//                 </select>
//               </div>

//               {formData.type === 'tokens' ? (
//                 <div className="mb-3">
//                   <label className="form-label">Amount of XDC</label>
//                   <select
//                     name="amount"
//                     value={formData.amount}
//                     onChange={handleInputChange}
//                     className="form-select"
//                   >
//                     <option value="5">5 XDC</option>
//                     <option value="10">10 XDC</option>
//                     <option value="20">20 XDC</option>
//                     <option value="50">50 XDC</option>
//                   </select>
//                 </div>
//               ) : (
//                 <div className="mb-3">
//                   <label className="form-label">NFT ID</label>
//                   <select
//                     name="nftId"
//                     value={formData.nftId}
//                     onChange={handleInputChange}
//                     className="form-select"
//                   >
//                     <option value="1">Test NFT #1</option>
//                     <option value="2">Test NFT #2</option>
//                     <option value="3">Test NFT #3</option>
//                   </select>
//                 </div>
//               )}
//             </div>
//           </div>

//           <button
//             type="submit"
//             className={`btn btn-primary w-100 ${loading ? 'disabled' : ''}`}
//             disabled={loading}
//           >
//             {loading ? 'Processing...' : `Request ${formData.type === 'tokens' ? formData.amount + ' XDC' : 'NFT'}`}
//           </button>
//         </form>

//         {error && <div className="alert alert-danger mt-3">{error}</div>}
//         {success && <div className="alert alert-success mt-3">{success}</div>}
//         <p className="text-muted mt-3">
//           {`You are requesting ${formData.type === 'tokens' ?
//             `${formData.amount} XDC tokens` :
//             `NFT #${formData.nftId}`} on ${formData.network} testnet`}
//         </p>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Faucet;
