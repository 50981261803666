import { addressService } from "../../../../store/actions";
import { toast } from 'react-toastify';

export const verifyContract = async (contractAddress: string, sourceCode: string) => {
    const verificationPayload = {
      compiler_version: "v0.8.28+commit.7893614a",
      source_code: sourceCode,
      is_optimization_enabled: true,
      is_yul_contract: false,
      optimization_runs: "200",
      libraries: {
        "": ""
      },
      evm_version: "cancum",
      autodetect_constructor_args: true,
      constructor_args: "",
      license_type: "mit",
    };
  
    try {
      const verificationResponse = await addressService.verifyContract(
        contractAddress,
        'flattened-code',
        verificationPayload
      );
  
      toast.success("Contract verification submitted successfully!");
      return verificationResponse;
    } catch (error: any) {
      toast.error("Contract verification failed: " + (error.message || "Unknown error"));
      throw error;
    }
  };
  