import React, {FC, useEffect, useState} from "react";
import {dashboardService} from "../../../../store/actions";
import {getFiat, getXDCAddress, serializeQuery} from "../../../../common/utils/function";
import useDebouncedEffect from "use-debounced-effect";
import Select, {components, DropdownIndicatorProps, OptionProps, PlaceholderProps} from "react-select";
import {useNavigate} from "react-router-dom";
import useCommon from "../../../../common/hooks/useCommon";
import {useTranslation} from "react-i18next";
import TokenImage from "../../../common/token-image";
import {CSSObject} from '@emotion/react';

const SearchBar: FC = () => {
    const {get0x, getXDC} = useCommon();
    const navigate = useNavigate();
    const [defaultList, setDefaultList] = useState<any[]>([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const {t, i18n} = useTranslation();

    const getSearchData = (initial?: any) => {
        setLoading(true);
        if (search?.length || initial) {
            dashboardService.search(serializeQuery({q: get0x(search || "token")})).then((response) => {
                const sortedResponse = response
                    .map((e: any) => {
                        e.label = `${e.name || e.transaction_hash || e.block_number || getXDC(e.address)} (${e.symbol || ''})`;
                        e.value = e.transaction_hash || e.block_number || getXDC(e.address);
                        e.symbol = e.symbol || "";
                        return e;
                    })
                    .sort((a: any, b: any) => {
                        if (a.type === "token" && b.type !== "token") return -1;
                        if (a.type !== "token" && b.type === "token") return 1;

                        if (a.type === "token" && b.type === "token") {
                            const exchangeRateA = parseFloat(a.exchange_rate || "0");
                            const exchangeRateB = parseFloat(b.exchange_rate || "0");
                            if (exchangeRateA !== exchangeRateB) {
                                return exchangeRateB - exchangeRateA;
                            }
                            return a.symbol.localeCompare(b.symbol);
                        }
                        return a.name.localeCompare(b.name);
                    });
                if (!defaultList?.length) setDefaultList(sortedResponse ?? []);
                setOptions(sortedResponse);
                setLoading(false);
            }).catch((e) => {
                console.error("Failed to fetch search data:", e);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    };

    const handle = {
        search: (value: string) => {
            setSearch(value);
            if (!value?.length) {
                const sortedDefaultList = defaultList
                    .sort((a: any, b: any) => {
                        if (a.type === "token" && b.type !== "token") return -1;
                        if (a.type !== "token" && b.type === "token") return 1;
                        if (a.type === "token" && b.type === "token") {
                            const exchangeRateA = parseFloat(a.exchange_rate || "0");
                            const exchangeRateB = parseFloat(b.exchange_rate || "0");
                            if (exchangeRateA !== exchangeRateB) {
                                return exchangeRateB - exchangeRateA;
                            }
                            return a.symbol.localeCompare(b.symbol);
                        }
                        return a.name.localeCompare(b.name);
                    });
                setOptions(JSON.parse(JSON.stringify(sortedDefaultList)));
            } else {
                const filterOptions = defaultList.filter((option) => {
                    return (
                        option?.name?.toLowerCase().includes(value.toLowerCase() || "") ||
                        option?.symbol?.toLowerCase().includes(value.toLowerCase() || "") ||
                        option?.transaction_hash?.toLowerCase().includes(value.toLowerCase() || "")
                    );
                });
                setOptions(filterOptions);
            }
        },
        select: (e: any) => {
            switch (e.type) {
                case "block": {
                    navigate(`/block/${e.value}`);
                    break;
                }
                case "transaction": {
                    navigate(`/tx/${e.value}`);
                    break;
                }
                case "address": {
                    navigate(`${e.url}`);
                    break;
                }
                case "token": {
                    navigate(`${e.token_url}`);
                    break;
                }
                default:
                    break;
            }
            setSearch("");
        }
    };

    const DropdownIndicator = (props: DropdownIndicatorProps<any>) => (
        <components.DropdownIndicator {...props}>
            <i className="bi-search"/>
        </components.DropdownIndicator>);
    const Option = (props: OptionProps<any>) => {
        return (
            <components.Option {...props}>
                {props?.data?.type === "token" ? (
                    <div className="nav-link p-1 d-flex align-items-start w-100 rounded">
                        <div className="me-2">
                            <TokenImage image={props?.data?.icon_url} symbol={props?.data?.symbol} params={{width: 16, className: "me-2"}}/>
                        </div>
                        <div className="flex-fill text-truncate">
                            <h6 className="d-flex align-items-center fw-normal mb-0">
                                <div className="text-truncate me-2">
                                    <span>{props?.data?.name}
                                        {/* ({props?.data?.symbol}) */}
                                    </span>
                                </div>
                                <span className="bs-badge h-small h-gray bg-gray text-dark">
                                    {getFiat(props?.data?.exchange_rate, 4)}
                                </span>
                                {props?.data?.is_smart_contract_verified ? (
                                    <i className="bi bi-patch-check-fill text-info me-2 ms-auto"></i>
                                ) : (
                                    <i className="bi bi-patch-check me-2 ms-auto"></i>
                                )}
                            </h6>
                            <span className="text-muted text-truncate small"> {getXDCAddress(props?.data?.address)}</span>
                            <div className="text-muted text-truncate small">{props?.data?.website}</div>
                        </div>
                    </div>
                ) : props?.data?.type === "transaction" ? (
                    <p className="d-flex align-items-center">
                        <i className="bi bi-arrow-left-right me-2"></i>
                        <span>{props?.data?.label.replace(/\s*\(.*?\)\s*$/, '')}</span>
                    </p>
                ) : props?.data?.type === "address" ? (
                    <p className="d-flex align-items-center">
                        <div
                            className="paper"
                            style={{
                                borderRadius: "50px",
                                display: "inline-block",
                                marginRight: "8px",
                                overflow: "hidden",
                                padding: "0px",
                                backgroundColor: "rgb(21, 136, 242)",
                                height: "20px",
                                width: "20px",
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="20" width="20">
                                <rect x="0" y="0" rx="0" ry="0" height="20" width="20" transform="translate(-3.720709885501791 -1.3035126306609586) rotate(329.3 10 10)"
                                      fill="#017c8c"></rect>
                                <rect x="0" y="0" rx="0" ry="0" height="20" width="20" transform="translate(-4.693718281454541 8.24264966254915) rotate(218.0 10 10)"
                                      fill="#f1b002"></rect>
                                <rect x="0" y="0" rx="0" ry="0" height="20" width="20" transform="translate(-12.966521436905857 3.856388646296716) rotate(255.3 10 10)"
                                      fill="#f3d500"></rect>
                                <rect x="0" y="0" rx="0" ry="0" height="20" width="20" transform="translate(-7.186895882619045 24.555090177171703) rotate(119.3 10 10)"
                                      fill="#f75201"></rect>
                            </svg>
                        </div>
                        <span>{props?.data?.label.replace(/\s*\(.*?\)\s*$/, '')}</span>
                    </p>
                ) : (
                    <p>{props?.data?.label.replace(/\s*\(.*?\)\s*$/, '')}</p>
                )}
            </components.Option>
        );
    };

    const CustomPlaceHolder = (props: PlaceholderProps<any>) => {
        return (<components.Placeholder {...props}>
                <TokenImage image={"https://static.xx.fbcdn.net/rsrc.php/yS/r/hh3i_Xswcd4.ico"} params={{width: 18, height: 18, className: "me-2"}}/>
                <p>{t("Ask AI | Search by Address | Txn Hash | Block | Token... ")}</p>
            </components.Placeholder>
        )
    }

    useDebouncedEffect(() => getSearchData(), 1000, [search]);

    useEffect(() => {
        getSearchData(true)
    }, [])

    return (
        <React.Fragment>
            <div className="bs-search">
                <Select
                    value={options.find(option => option.value === search) || null}
                    onInputChange={(value) => handle.search(value)}
                    inputValue={search}
                    options={options}
                    //@ts-ignore
                    placeholder={{CustomPlaceHolder}}
                    onChange={handle.select}
                    filterOption={() => true}
                    noOptionsMessage={() =>
                        loading ? `${t("Loading...")}` : `${t("No Record Found")}`
                    }
                    classNamePrefix="bs-search"
                    components={{
                        DropdownIndicator,
                        Option,
                        Placeholder: CustomPlaceHolder,
                        IndicatorSeparator: () => null
                    }}
                    styles={{
                        placeholder: (base) => {
                            return {
                                ...base,
                                pointerEvents: "none",
                                userSelect: "none",
                                MozUserSelect: "none",
                                WebkitUserSelect: "none",
                                msUserSelect: "none"
                            };
                        },
                        input: (base) => ({
                            ...base,
                            gridTemplateColumns: "0 minmax(min-content, 1fr)"
                        }),
                        option: (base: CSSObject, {isFocused, isSelected}: { isFocused: boolean, isSelected: boolean, isDisabled: boolean }) => {
                            return {
                                ...base,
                                backgroundColor: isSelected ? "var(--bs-bg-secondary)" : isFocused ? "var(--bs-bg-secondary)" : undefined,
                            };
                        }
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default SearchBar;
