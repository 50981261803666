import {createSlice} from "@reduxjs/toolkit";
import CONFIG from "../../common/utils/config.json";

export const common = createSlice({
    name: "common",
    initialState: {
        settings: {},
        tokenType: process.env.REACT_APP_TOKEN_TYPE || "XRC",
        prefix: localStorage.getItem("prefix") || process.env.REACT_APP_PREFIX || "xdc",
        isRelativeTimeFormat: false,
        isUTCTimeZoneFormat: false,
        fiat: localStorage.getItem("fiat") || "USD",
        fiatList: localStorage.getItem("fiatList") ? JSON.parse(localStorage.getItem("fiatList") as string) : {},
        usdPrice: {},
        stats: {},
        hoverAddress: "",
        config: {},
        dashboard: {},
        account: localStorage.getItem("account") ? JSON.parse(localStorage.getItem("account") as string) : null,
        defiList: []
    },
    reducers: {
        actionPrefix: (state) => {
            const localPrefix = state.prefix !== "0x" ? "0x" : process.env.REACT_APP_PREFIX || "xdc";
            localStorage.setItem("prefix", String(localPrefix));
            window.location.reload();
            state.prefix = localPrefix;
        },
        actionIsRelativeTimeFormat: (state, value) => {
            localStorage.setItem("isRelativeTimeFormat", String(value.payload));
            state.isRelativeTimeFormat = value.payload;
        },
        actionIsUTCTimeZoneFormat: (state, value) => {
            localStorage.setItem("isUTCTimeZoneFormat", String(value.payload));
            state.isUTCTimeZoneFormat = value.payload;
        },
        actionFiatList: (state, value) => {
            localStorage.setItem("fiatList", JSON.stringify(value.payload || {}));
            state.fiatList = value.payload || {USD: 0};
        },
        actionConfig: (state, value) => {
            state.config = value.payload || CONFIG || {};
        },
        actionDashboard: (state, value) => {
            state.dashboard = value.payload || CONFIG || {};
        },
        actionFiat: (state, value) => {
            localStorage.setItem("fiat", value.payload || "USD");
            state.fiat = value.payload || "USD";
        },
        actionSettings: (state, value) => {
            state.settings = value.payload;
        },
        actionUSDPrice: (state, value) => {
            state.usdPrice = value.payload;
        },
        actionStats: (state, value) => {
            state.stats = value.payload;
        },
        actionHoverAddress: (state, value) => {
            state.hoverAddress = value.payload;
        },
        actionAccount: (state, value) => {
            if (!!value.payload) {
                const data = {...state.account, ...value.payload}
                localStorage.setItem("account", JSON.stringify(data))
                state.account = data
            } else {
                localStorage.removeItem("account")
                state.account = null
            }
        },
        actionDefi: (state, value) => {
            state.defiList = value.payload;
        },
    },
});

// Action creators are generated for each case reducer function
// export const { actionIsRelativeTimeFormat } = common.actions

export default common.reducer;
